<!--
 * @Description: 重装单
 * @Author: ChenXueLin
 * @Date: 2021-10-12 15:19:02
 * @LastEditTime: 2023-02-20 16:21:51
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">重装任务单</div>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                ref="baseInfoForm"
                class="editForm"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="baseInfoFormRules"
                :model="baseInfoForm"
              >
                <el-form-item label="客户名称" prop="corpId">
                  <e6-vr-select
                    v-model="baseInfoForm.corpId"
                    :data="corpList"
                    placeholder="客户名称"
                    title="客户名称"
                    clearable
                    virtual
                    remote
                    :is-title="true"
                    @filterChange="handleLoadCorpFilter"
                    @change="handleChange"
                    :props="{
                      id: 'corpId',
                      label: 'corpName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="联系人" prop="linkmanrpid">
                  <div class="select-content">
                    <e6-vr-select
                      v-model="baseInfoForm.linkmanrpid"
                      :data="contactList"
                      placeholder="联系人"
                      title=""
                      :props="{
                        id: 'contactId',
                        label: 'contactName'
                      }"
                      clearable
                      @change="handleContact"
                      @clear="handleClear"
                    ></e6-vr-select>
                    <el-button
                      type="text"
                      style="margin-left: 10px"
                      @click="addConcat"
                    >
                      添加联系人
                    </el-button>
                  </div>
                </el-form-item>

                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    placeholder="联系电话"
                    v-model="baseInfoForm.contactPhone"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系地址" prop="defaultCity">
                  <e6-vr-select
                    style="width:300px"
                    v-model="baseInfoForm.defaultCity"
                    :data="addressList"
                    placeholder="联系地址"
                    title="联系地址"
                    virtual
                    clearable
                    :is-title="true"
                    remote
                    @filterChange="handleLoadAddressFilter"
                    @change="handleCitySelect"
                    :props="{
                      id: 'fullName',
                      label: 'fullName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="insAddress">
                  <el-input
                    placeholder="详细地址"
                    v-model="baseInfoForm.insAddress"
                  ></el-input>
                </el-form-item>
                <el-form-item label="要求完成时间" prop="expecttime">
                  <el-date-picker
                    v-model="baseInfoForm.expecttime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="timestamp"
                    popper-class="special"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="baseInfoForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    :rows="3"
                    style="width: 500px"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="addScene">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="handleDelete">
                    删除任务项
                  </el-button>
                  <!-- <el-button type="primary">
                    导入任务项
                  </el-button> -->
                </div>
              </div>
              <el-table
                ref="multipleTable"
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column
                  type="selection"
                  width="50"
                  :selectable="selectable"
                ></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneName"
                  label="场景名称"
                  width="160"
                  fixed
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="secondClassName"
                  label="安装组合"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show2">{{ row.secondClassName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="thirdClassName"
                  label="已安装商品"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="firstClassName"
                  label="商品分类"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="equipCode"
                  label="设备编号"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="virtualTypeName"
                  label="类型"
                  width="160"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="newSceneName"
                  width="160"
                  label="新场景名称"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.newSceneName"
                      @input="
                        scope.row.newSceneName = scope.row.newSceneName.replaceAll(
                          ' ',
                          ''
                        )
                      "
                      v-if="scope.row.show"
                    ></el-input>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  show-overflow-tooltip
                  prop="newSceneType"
                  label="新场景类型"
                  width="160"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="scope">
                    <e6-vr-select
                      v-if="scope.row.show"
                      v-model="scope.row.newSceneType"
                      :data="newScene"
                      placeholder="新场景类型"
                      title=""
                      :props="{
                        id: 'sceneType',
                        label: 'sceneTypeName'
                      }"
                      clearable
                    ></e6-vr-select>
                  </template>
                </el-table-column> -->
              </el-table>
            </div>
          </template>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
      <!-- 选择场景 -->
      <select-scene
        v-loading="dialogLoading"
        :element-loading-background="loadingBackground"
        :selectSceneDialog="selectSceneDialog"
        :unknownScene="unknownScene"
        @handleClose="handleClose"
        @getData="getData"
        :addType="1"
        :corpId="baseInfoForm.corpId"
      ></select-scene>
      <!-- 添加联系人弹框 -->
      <add-concat-dialog
        :addContactVisible="addContactVisible"
        @handleClose="handleClose"
        @updateList="updateList"
        :isNeedReq="true"
        :corpName="baseInfoForm.corpName"
        :corpId="baseInfoForm.corpId"
      ></add-concat-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import SelectScene from "@/components/workOrderManage/selectScene.vue";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import addTask from "@/mixins/addTask";
import { getSceneNameList, createReinstall, getSceneNumber } from "@/api";
import { printError } from "@/utils/util";
import { telAndMobileValid } from "@/utils/validate";
export default {
  name: "reinstallOrder",
  components: { SelectScene, AddConcatDialog },
  data() {
    return {
      nowDate: new Date().setHours(new Date().getHours() + 4),
      loading: false,
      workSecondClass: "3",
      dialogLoading: false,
      isConfirm: 1,
      baseInfoForm: {
        corpId: "", //公司id
        remark: "",
        insAddress: "", //详细地址
        linkmanrpid: "", //联系人id
        contactPhone: "", //电话
        defaultCity: "", //默认选中地址
        expecttime: new Date().setHours(new Date().getHours() + 4)
      }, //基本信息
      unknownScene: true, //是否需要未知场景
      baseInfoFormRules: {
        defaultCity: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"]
          }
        ],
        insAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["blur", "change"]
          }
        ],
        corpId: [
          {
            required: true,
            message: "请选择客户名称",
            trigger: ["blur", "change"]
          }
        ],
        linkmanrpid: [
          {
            required: true,
            message: "请选择联系人名称",
            trigger: ["blur", "change"]
          }
        ],
        expecttime: [
          {
            required: true,
            message: "请选择解决时间",
            trigger: ["blur", "change"]
          }
        ],
        contactPhone: telAndMobileValid.required({
          requiredMsg: "请输入手机号码",
          message: "号码格式不正确",
          trigger: ["blur", "change"]
        })
      },
      newScene: [] //新场景类型,
    };
  },
  mixins: [base, addTask],
  created() {
    this.getNewScene();
  },
  watch: {},
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //获取新场景类型
    async getNewScene() {
      try {
        this.dialogLoading = true;
        let res = await getSceneNumber({ corpId: this.corpId });
        this.newScene = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取场景类型
    async getSceneType() {
      try {
        let res = await getSceneNameList();
        this.sceneTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 主机禁止删除
     */
    selectable(row) {
      if (row.virtualType == 1 || !row.equipId) {
        return true;
      } else {
        return false;
      }
    },
    /**
     删除任务项
     */
    handleDelete() {
      if (!this.deleteData.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      let tableData = _.cloneDeep(this.tableData);
      let deleteData = _.cloneDeep(this.deleteData);
      this.tableData = tableData.filter(v =>
        deleteData.every(n => {
          return !(n.sceneId == v.sceneId && n.terminalId == v.terminalId);
        })
      );
      this.deleteData = [];
      this.$refs.multipleTable.clearSelection();
      this.getData([]);
    },
    //添加联系人之后更新下拉框
    updateList() {
      this.getContactList();
    },
    //点击确定
    submit() {
      this.$refs.baseInfoForm.validate(valid => {
        if (valid) {
          // if (!this.baseInfoForm.insCountyName) {
          //   this.$message.warning("区县不能为空");
          //   return;
          // }
          if (this.baseInfoForm.expecttime < this.nowDate) {
            this.$message.warning("要求完成时间不能小于当前时间+4小时");
            return;
          }
          this.baseInfoForm.contactName = this.filterSelect(
            this.contactList,
            "contactId",
            this.baseInfoForm.linkmanrpid
          ).contactName;

          if (!this.tableData.length) {
            this.$message.warning("请至少选择一个任务项");
            return;
          }
          this.checkCreateTaskReq();
        }
      });
    },

    //创建维修单请求
    async createTaskReq() {
      try {
        this.loading = true;
        let res = await createReinstall({
          ...this.baseInfoForm,
          isConfirm: this.isConfirm,
          taskItemReloadReqVOList: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          // this.routerPush({
          //   name: "taskListManage/allTaskList",
          //   params: {
          //     refresh: true
          //   }
          // });
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击取消返回上一页
    handleGoBack() {
      this.closeTag(this.$route);
      this.routerPush({
        name: "taskListManage/allTaskList",
        params: {
          refresh: true
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
}
/deep/.edit-address {
  .el-form-item__label {
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
</style>
